<template>
  <b-card>
    <b-form>
      <b-row>
        
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="MailIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Application Setttings
            </h4>
          </div>
        </b-col>
           <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
             
            <h6 class="mb-0 ">
            Config Version :  {{ appConfigSettings.config_version }}
            </h6>
          </div>
        </b-col>

          <b-col class="mt-1" md="6">
          <b-form-group
            label-for="app_title"
            label="Name"
          >
              <b-form-input
              id="app_title"
                class="form-control"
                
             
              v-model="appConfigSettings.title"
              placeholder="Application Name"
            />
          </b-form-group>
        </b-col>
        <b-col class="mt-1" md="6">
          <b-form-group
            label-for="app_font"
            label="Font Name"
          >
              <b-form-input
              id="app_font"
                class="form-control"
                
             
              v-model="appConfigSettings.preferred_font"
              placeholder="Font Name"
            />
          </b-form-group>
        </b-col>





        <b-col class="mt-1" md="6">
          <b-form-group
            label-for="splash_logo"
            label="Splash Logo"
          >
            
         
          <b-media no-body>
          <b-media-aside>
      
          <b-img
            ref="splashpreviewEl"
            rounded
            :src="appConfigSettings.splash_logo"
            height="80"
               width="80"
          />
          <b-media-body class="mt-75 ml-75">
          <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
           :state="Boolean(splashfile)"
          v-model="splashfile"
          class="mb-75 mr-75"
          @click="$refs.splashpreviewEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="splashpreviewEl"
          v-model="splashprofileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="fileSelected"
        />
         <!-- reset -->
         <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
          @click="resetSplash()"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
    
          </b-media-body>
       
        <!--/ avatar -->
      </b-media-aside>
          </b-media>
          </b-form-group>
        
        </b-col>



        <b-col class="mt-1" md="6">
          <b-form-group
            label-for="nav_logo"
            label="Navigation Bar Logo"
          >
          <b-media no-body>
          <b-media-aside>
      
          <b-img
            ref="navpreviewEl"
            rounded
            :src="appConfigSettings.nav_logo"
            height="80"
               width="80"
          />
          <b-media-body class="mt-75 ml-75">
          <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
           :state="Boolean(navfile)"
          v-model="navfile"
          class="mb-75 mr-75"
          @click="$refs.refnavInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refnavInputEl"
          v-model="navprofileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="navLogoSelected"
        />
          <!-- reset -->
          <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
              @click="resetNavlogo()"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
    
          </b-media-body>
       
        <!--/ avatar -->
      </b-media-aside>
          </b-media>
          </b-form-group>
        </b-col>





        <b-col class="mt-1" md="12">
          <b-form-group
            label-for="smtp_port"
            label="Privacy Url"
          >
            <b-form-input
              id="smtp_port"
              v-model="appConfigSettings.privacy_url"
              placeholder="Privacy Url"
            />
          </b-form-group>
        </b-col>
        <b-col class="mt-1" md="12">
          <b-form-group
            label-for="terms_url"
            label="Terms Url"
          >
            <b-form-input
              id="terms_url"
              v-model="appConfigSettings.terms_url"
              placeholder="Terms Url"
            />
          </b-form-group>
        </b-col>
        <b-col class="mt-1" md="12">
          <b-form-group
            label-for="About_url"
            label="About Url"
          >
            <b-form-input
              id="About_url"
              v-model="appConfigSettings.about_url"
              placeholder="About Url"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>

        <b-col class="mt-1" md="6">
         

            <b-form-group
            label="Background Option"
            label-for="isSecured"
          >
           <div class="demo-inline-spacing mb-1">
            <b-form-radio v-model="appConfigSettings.backgroundOption"  name="background_option" v-bind:value="1" >
              Background Image
            </b-form-radio>
            <b-form-radio  v-model="appConfigSettings.backgroundOption"  name="background_option" v-bind:value="0" >
              Background Solid Color
            </b-form-radio>
            <b-form-radio  v-model="appConfigSettings.backgroundOption"  name="background_option" v-bind:value="2" >
              Gradient Background Color
            </b-form-radio>
          </div>
          </b-form-group>


          <b-form-group
            label-for="back_logo"
            label="Background Image"
          >
       
          <b-media no-body>
          <b-media-aside>
      
          <b-img
            ref="backpreviewEl"
            rounded
            :src="appConfigSettings.background_image"
            height="80"
               width="80"
          />
          <b-media-body class="mt-75 ml-75">
          <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
           :state="Boolean(backfile)"
          v-model="backfile"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="backprofileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="backImageSelected"
        />
          <!-- reset -->
          <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
           @click="resetBackImage()"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
    
          </b-media-body>
       
        <!--/ avatar -->
      </b-media-aside>
          </b-media>
          </b-form-group>
        </b-col>

        <b-col class="mt-1  ml-1 mr-1" md="3">
        
          <b-form-group
            label-for="terms_url"
            label="Background Color"
          >
      
          <div>
          <LvColorpicker  :key="colorPickerKey + '_background_color'"  v-model="appConfigSettings.background_color" :clearable="true"
          :bottomBar="true" />
        </div>
        
          </b-form-group>

        
        </b-col>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
       

            <b-col cols="12">
          <div class="d-flex align-items-center ">
            
           
          </div>
        </b-col>
        <b-col class="  ml-1 mr-1" md="3">
        
          <b-form-group
            label-for="terms_url"
            label="Gradient Background"
          >
          
         
            
    
          <LvColorpicker  :key="colorPickerKey + '_gradient_start_color'"  v-model="appConfigSettings.gradient_start_color" :clearable="true"
          :bottomBar="true" />
          <b-card-text>Start Color</b-card-text>
           
          <LvColorpicker  :key="colorPickerKey + '_gradient_end_color'"  v-model="appConfigSettings.gradient_end_color" :clearable="true"
          :bottomBar="true" />
          <b-card-text>End Color</b-card-text>
         
          </b-form-group>

        
        </b-col>
        <b-col class="mt-1  ml-1 mr-1" md="3">
          
          <b-form-group
            label-for="terms_url"
            label="Gradient Type"
          >
          
          <v-select 
          v-model="appConfigSettings.gradient_type"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="gradient type"
          :options="gradient_types" 
          
          class="select-size-lg">
          
        </v-select>
           
            
         
          </b-form-group>

        
        </b-col>
        <b-col class="mt-1  ml-1 mr-1" md="3">
          
          <b-form-group
            label-for="terms_url"
            label="Gradient Direction"
          >
          
          <v-select  
          v-model="appConfigSettings.gradient_start"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="Direction"
          :options="gradient_directions" 
          
          class="select-size-lg">
          
        </v-select>
           
        <b-card-text>Start Position</b-card-text>
          <v-select  
          v-model="appConfigSettings.gradient_end"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="Direction"
          :options="gradient_directions" 
          
          class="select-size-lg">
          
        </v-select>
        <b-card-text>End Position</b-card-text>
          </b-form-group>

        
        </b-col>

        <b-col cols="12">
          <hr class="my-2">
        </b-col>
     

        <b-col class="mt-1  ml-1 mr-1" md="3">
          <b-form-group
            label-for="terms_url"
            label="Foreground Color"
          >
          <LvColorpicker  :key="colorPickerKey + '_foreground_color'" v-model="appConfigSettings.foreground_color" :clearable="true"
          :bottomBar="true" />
          </b-form-group>

        
        </b-col>

        <b-col class="mt-1  ml-1 mr-1" md="3">
          <b-form-group  label-for="terms_url"
            label="Bar Tint Color"
          >
          <LvColorpicker  :key="colorPickerKey + '_bar_tint_color'"  v-model="appConfigSettings.bar_tint_color" :clearable="true"
          :bottomBar="true" />
          </b-form-group>

        
        </b-col>
        <b-col class="mt-1  ml-1 mr-1" md="3">
          <b-form-group
            label-for="terms_url"
            label="Bar Text Color"
          >
          <LvColorpicker  :key="colorPickerKey + '_bar_text_color'"  v-model="appConfigSettings.bar_text_color" :clearable="true"
          :bottomBar="true" />
          </b-form-group>

        
        </b-col>
        <b-col class="mt-1  ml-1 mr-1" md="3">
          <b-form-group
            label-for="inactive_url"
            label="Bar in Active Text Color"
          >
          <LvColorpicker  :key="colorPickerKey + '_bar_inactive_text_color'"  v-model="appConfigSettings.bar_inactive_text_color" :clearable="true"
          :bottomBar="true" />
          </b-form-group>

        
        </b-col>

        <b-col class="mt-1  ml-1 mr-1" md="3">
          <b-form-group
            label-for="inactivebutton_url"
            label="Inactive Button Color"
          >
          <LvColorpicker  :key="colorPickerKey + '_inactive_button_color'"  v-model="appConfigSettings.inactive_button_color" :clearable="true"
          :bottomBar="true" />
          </b-form-group>

        
        </b-col>

        <b-col cols="12">
          <hr class="my-2">
        </b-col>



        <b-col md="6">
          <b-form-group
            label="Notification Enabled"
            label-for="isSecured"
          >
           <div class="demo-inline-spacing mb-1">
            <b-form-radio v-model="appConfigSettings.notification_enabled"  name="notification_enabled" v-bind:value="false" >
              Disabled
            </b-form-radio>
            <b-form-radio v-model="appConfigSettings.notification_enabled"   name="notification_enabled" v-bind:value="true" >
              Enabled
            </b-form-radio>
          </div>
          </b-form-group>
        </b-col>


   

        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
             
            <h6 class="mb-0 ">
             
            </h6>
          </div>
        </b-col>

        <b-col md="6">
          <b-form-group
            label-for="Whatsapp-url"
            label="Whatsapp Url "
          >
            <b-form-input
              id="Whatsapp-url"
              v-model="appConfigSettings.whatsappUrl"
              placeholder="Whatsapp Url"
            />
          </b-form-group>
        </b-col>
         
     
         <b-col md="6">
          <b-form-group
            label-for="x-url"
            label="X Url "
          >
            <b-form-input
              id="x-url"
              v-model="appConfigSettings.xUrl"
              placeholder="X Url"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="smtp_password"
            label="Instagram Url"
          >
            <b-form-input
              id="smtp_password"
              v-model="appConfigSettings.instagramUrl"
              placeholder="Instagram"
            />
          </b-form-group>
        </b-col>

  <b-col md="6">
          <b-form-group
            label-for="account-google"
            label="Facebook Url"
          >
            <b-form-input
              id="account-google"
              v-model="appConfigSettings.facebookUrl"
              placeholder="Facebook Url"
            />
          </b-form-group>
        </b-col>
         <b-col md="6">
          <b-form-group
            label-for="linked_title"
            label="Linkedin Url"
          >
            <b-form-input
              id="linked_title"
              v-model="appConfigSettings.linkedinUrl"
              placeholder="Linkedin Url"
            />
          </b-form-group>
        </b-col>
          <b-col md="6">
          <b-form-group
            label-for="tiktok_title"
            label="Tiktok Url"
          >
            <b-form-input
              id="tiktok_title"
              v-model="appConfigSettings.tiktokUrl"
              placeholder="Tiktok Url"
            />
          </b-form-group>
        </b-col>
        <!--/ quora -->
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
      

        <b-col cols="12">
          <hr class="my-2">
        </b-col>
        <b-col cols="12">
  
      </b-col>
        <!-- Profile Connections -->
       
        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click.prevent="sendInformation"
          >
            Save changes
          </b-button>
         
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
 BMedia, BMediaAside, BMediaBody, BImg, BButton, BForm, BFormGroup,BFormFile, BFormInput, BRow, BCol, BCard, BCardText, BLink, BAvatar,  BFormRadio,   BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import LvColorpicker from 'lightvue/color-picker';

import axios from "@axios";
  import { ref, onUnmounted } from '@vue/composition-api'
  import store from '@/store'
    import { useUtils as useI18nUtils } from '@core/libs/i18n'
  import { useUtils as useAclUtils } from '@core/libs/acl'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from 'vue-select'
 import FormRepeaterBasic from './AboutItemRepeater.vue'

import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    FormRepeaterBasic,
    BImg,BMedia, BMediaAside, BMediaBody,  BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
     BCardText,
    BFormRadio,
        BFormCheckbox,LvColorpicker,vSelect

  },
  directives: {
    Ripple,
  },
  props: {
    smtpsettings: {
      type: Object,
      default: () => {},
    },
  },
   created() {
   
 


  },
  computed: {
  foregroundColor: {
    get() {
      console.log("in computed value: ",this.appConfigSettings.background_color)
      return this.appConfigSettings.background_color;
    },
    set(value) {
      this.appConfigSettings.foreground_color = value;
    },
  },
},
 
  data() {
   

    return {
      gradient_types:['LINEAR','RADIAL','SWEEP','DIAMOND'],
      gradient_directions:["topLeft" , "topCenter"  , "topRight" ,"centerLeft" ,"center" , "centerRight" ,"bottomLeft" , "bottomCenter", "bottomRight" ],
      
     localSettings:{}, 
     colorPickerKey: 0, 
     splashprofileFile: null,
     splashfile: null,
     navfile: null,
     navprofileFile: null,
     backfile: null,
     backprofileFile: null,
      isUpdated: false,
          isLoading: false,
    }
  },
  watch: {
    appConfigSettings:   {
        handler:function (n, o)   {
          console.log('appseting changed in watch ', n);

                  
                    console.log('new client: ', n);
                    this.colorPickerKey++;
             
        

        },
        deep: true
    }
    
},
  setup(props, { root }) {
      const clientData = ref(null)
    
      const appConfig = ref({})
      const background = ref(null);

     // background.value="#fff132";
  
      const appConfigSettings = ref({})
  
      const toast = useToast()
  
  
      const USER_APP_STORE_MODULE_NAME = 'apps-clients-settings'
  
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, clientSettingsStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      })
  
    const getAppConfig=  (ctx, callback) => {  
        store.dispatch('apps-clients-settings/fetchAppConfig' )
        .then(response => {
          //    var course=JSON.parse(JSON.stringify(response.data));
        //  const { course  } = response.data
          
          console.log('get app config result')
          console.log(response.data.data);
           appConfigSettings.value=response.data.data;
      
         
        console.log("this background",appConfigSettings.value.background_color )
   
        })
        .catch(error => {
          console.log("Error in ap settings fetch")
          console.log(error);
          if (error.response.status === 404) {
            
          }
        })

    }
       
  
  
      const updateAppConfig= ()=> {
      
        console.log("loyaltySettings in update:",appConfigSettings.value);
        return;

        return  store.dispatch('apps-clients-settings/updateAppConfig',appConfigSetting.value )
        .then(response => {
          //    var course=JSON.parse(JSON.stringify(response.data));
        //  const { course  } = response.data
          
          console.log('updateLoyaltySettings')
          console.log(response.data.data);
          appConfigSettings.value=response.data.data;
             
            return;
          
            toast({
            component: ToastificationContent,
            props: {
              title: 'Setting updated',
              icon: 'AlertSuccessIcon',
              appendToast: true,
              solid:true,
              variant: 'success',
            },
          })
          //loyaltyData.value= response.data.data ;
  
          console.log('=========')
  
          // console.log(response.data);
        })
        .catch(error => {
         
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching courses list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          
        })
      };
      getAppConfig();
      
        const { t } = useI18nUtils()
      return {
        getAppConfig,
        updateAppConfig,
        appConfigSettings,
        background,
        clientData,
        t
      }
    },
  methods: {
    resetSplash(){
      this.splashprofileFile= null;
      this.splashfile= null;
      this.appConfigSettings.splash_logo=null;

    },
    resetNavlogo(){
      this.navprofileFile= null;
      this.navfile= null;
      this.appConfigSettings.nav_logo=null;

    },
    resetBackImage(){
      this.backprofileFile= null;
      this.backfile= null;
      this.appConfigSettings.background_image=null;

    },

    backImageSelected(payload) {
        console.log("file selected");
        if (payload==null||payload==undefined) return;
        const formData = new FormData();
        formData.append("file", payload);
        formData.append("radioimage", this.appConfigSettings.id);
      
        formData.append("platform", "webadmin");
        this.isLoading = true;
  
        axios
          .post("/upload/admin", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
 
            this.appConfigSettings.background_image= response.data.url;
            this.isUpdated = true;
            this.isLoading = false;

            this.backfile = null;
            this.backprofileFile = null;
           
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
            this.$bvToast.toast(`${error.response.data.message}`, {
              title: "Error",
              autoHideDelay: 5000,
              appendToast: true,
              variant: "danger",
              solid: true,
            });
          });
      },
    navLogoSelected(payload) {
        console.log("file selected");
        if (payload==null||payload==undefined) return;
        const formData = new FormData();
        formData.append("file", payload);
        formData.append("radioimage", this.appConfigSettings.id);
      
        formData.append("platform", "webadmin");
        this.isLoading = true;
  
        axios
          .post("/upload/admin", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
 
            this.appConfigSettings.nav_logo= response.data.url;
            this.isUpdated = true;
            this.isLoading = false;

            this.navfile = null;
            this.navprofileFile = null;
           
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
            this.$bvToast.toast(`${error.response.data.message}`, {
              title: "Error",
              autoHideDelay: 5000,
              appendToast: true,
              variant: "danger",
              solid: true,
            });
          });
      },
    fileSelected(payload) {
        console.log("file selected");
        if (payload==null||payload==undefined) return;
        const formData = new FormData();
        formData.append("file", payload);
        formData.append("radioimage", this.appConfigSettings.id);
      
        formData.append("platform", "webadmin");
        this.isLoading = true;
  
        axios
          .post("/upload/admin", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
 
            this.appConfigSettings.splash_logo= response.data.url;
            this.isUpdated = true;
            this.isLoading = false;

            this.spalshfile = null;
            this.splashprofileFile = null;
           
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
            this.$bvToast.toast(`${error.response.data.message}`, {
              title: "Error",
              autoHideDelay: 5000,
              appendToast: true,
              variant: "danger",
              solid: true,
            });
          });
      },
     sendInformation() {
     
     
      this.isLoading = true;
      axios({
        method: "put",
        url: `/admin/auth/appconfig`,
        data:this.appConfigSettings,
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.getAppConfig();
          
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    resetForm() {
      this.appConfigSettings = JSON.parse(JSON.stringify(this.smtpsettings))
    },
  },
}
</script>

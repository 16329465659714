<template>
  <b-card>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add New Option</span>
    </b-button>
      <b-form
        ref="Aboutform"
        :style="{ height: trHeight, minHeight: '400px' }"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >

          <!-- Item Name -->
          <b-col md="4">
            <b-form-group
              label="Item Name"
              label-for="item-name"
            >
              <b-form-input
                id="item-name"
                type="text"
                     v-model="item.title"
                placeholder="Title"
              />
            </b-form-group>
          </b-col>

          <!-- Cost -->
          <b-col md="3">
            <b-form-group
              label="Type"
              label-for="item-type"
            >
            <v-select  
                 id="item-type"
           v-model="item.itemType"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="Type"
          :options="itemTypes" 
          
          class="select-size-lg"
          single
        >
          
        </v-select>
            </b-form-group>
          </b-col>

         
          <b-col md="3" v-if="item.itemType=='URL'">
            <b-form-group
              label="Item Value"
              label-for="item-value"
            >
              <b-form-input
                id="item-value"
                type="text"
                       v-model="item.itemValue"
                placeholder="Content Value"
              />
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="item.itemType=='SCREEN'">
            <b-form-group
              label="Item Value"
              label-for="item-value"
            >
            <ScreenPicker 
            :itemId="item.id" 
             :initialCategories="item.itemValue"
             
            @updateSelectedScreen="screenUpdated"
                   />
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="item.itemType=='PAGE'">
            <b-form-group
              label="Item Value"
              label-for="item-value"
            >
            <PagePicker  :itemId="item.id" 
             :initialCategories="item.itemValue"
             
              @updateSelectedPage="pageUpdated"
                   />
        
            </b-form-group>
          </b-col>
          
          <!-- Remove Button -->
          <b-col
            lg="2"
            md="2"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Delete</span>
            </b-button>
          </b-col>
    
        </b-row>

      </b-form>
  



   

    <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click.prevent="sendInformation"
          >
            Save changes
          </b-button>
         
        </b-col>

  </b-card>
 
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,BCard
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import clientSettingsStoreModule from './clientSettingsStoreModule'
import vSelect from 'vue-select'
    import { useUtils as useI18nUtils } from '@core/libs/i18n'
   import { ref, onUnmounted } from '@vue/composition-api'
   import axios from "@axios";
  import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import PagePicker from './component/PagePicker.vue';
import ScreenPicker from './component/RadioSectionPicker.vue';
export default {
  components: {
    PagePicker,
    ScreenPicker,
    BCard,
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  setup(props, { root }) {
      const clientData = ref(null)
    
      const appConfig = ref({})
      const background = ref(null);

     // background.value="#fff132";
   
      const pages = ref([]);
      const screens = ref([]);

      
      const toast = useToast()
  
  
      const USER_APP_STORE_MODULE_NAME = 'apps-radio-aboutus'
  
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, clientSettingsStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      })
  
   
    const getAllPages=  (ctx, callback) => {  
        store.dispatch('apps-radio-aboutus/fetchAllPages' )
        .then(response => {
          //    var course=JSON.parse(JSON.stringify(response.data));
        //  const { course  } = response.data
          
          console.log('get all pages in radio about page result')
          
          pages.value=response.data.data;
          console.log(response.data.data);
       
        })
        .catch(error => {
          console.log("Error in fetchRadioAboutSections fetch")
          console.log(error);
          items.value=[];
          if (error.response.status === 404) {
            
          }
        })

    }
    const getAboutScreens=  (ctx, callback) => {  
        store.dispatch('apps-radio-aboutus/fetchRadioAboutSections' )
        .then(response => {
          //    var course=JSON.parse(JSON.stringify(response.data));
        //  const { course  } = response.data
          
          console.log('get radio about section result result')
          
          screens.value=response.data.data;
       
        })
        .catch(error => {
          console.log("Error in fetchRadioAboutSections fetch")
          console.log(error);
          screens.value=[];
          if (error.response.status === 404) {
            
          }
        })

    }
    
       
  
  
      const updateAppConfig= ()=> {
      
        console.log("loyaltySettings in update:",appConfigSettings.value);
        return;

        return  store.dispatch('apps-radio-aboutus/updateAppConfig',appConfigSetting.value )
        .then(response => {
          //    var course=JSON.parse(JSON.stringify(response.data));
        //  const { course  } = response.data
          
          console.log('updateLoyaltySettings')
          console.log(response.data.data);
          appConfigSettings.value=response.data.data;
             
            return;
          
            toast({
            component: ToastificationContent,
            props: {
              title: 'Setting updated',
              icon: 'AlertSuccessIcon',
              appendToast: true,
              solid:true,
              variant: 'success',
            },
          })
          //loyaltyData.value= response.data.data ;
  
          console.log('=========')
  
          // console.log(response.data);
        })
        .catch(error => {
         
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching courses list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          
        })
      };
      
      getAboutScreens();
      getAllPages();

      
        const { t } = useI18nUtils()
      return {
        getAboutScreens,
        getAllPages,
      
        updateAppConfig,
      
        screens,
        pages,
        background,
        clientData,
        t
      }
    },
  data() {
    return {
      items:[],
      itemTypes:['SCREEN','PAGE','URL'],
      old_pages: [{id:1,title:'About us',itemValue:1},{id:2,title:"Privacy",itemValue:1}],
      old_screens: [{id:1,title:'Frequencies',itemValue:2},{id:2,title:"Toplist",itemValue:2}],
      
    
      nextTodoId: 100, 
    }
  },
  mounted() {
  
    this.initTrHeight();
    console.log("Repeat form mounted");
    const formHeight = this.$refs.Aboutform.scrollHeight;
    console.log(formHeight);
    this.trSetHeight(formHeight);
   
  },
  created() {
    console.log("Repeat form created");
    console.log(this.trHeight);
    this.getAboutSections()
  
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
   
  methods: {
    
    getAboutSections  ()   {  
        store.dispatch('apps-radio-aboutus/fetchRadioAboutSections' )
        .then(response => {
          //    var course=JSON.parse(JSON.stringify(response.data));
        //  const { course  } = response.data
          
          console.log('get radio about section result result')
          
          this.items=response.data.data;
          
        
       
        })
        .catch(error => {
          console.log("Error in fetchRadioAboutSections fetch")
          console.log(error);
          items.value=[];
          
        })

    },
    pageUpdated(payload) {
      console.log("page updated");
      console.log(payload);
      var item=this.items.find((x)=>x.id===payload.item);
      item.itemValue=payload.selection.id;

     
      
    },
    screenUpdated(payload) {
      console.log("screen updated");
      console.log(payload);
      var item=this.items.find((x)=>x.id===payload.item);
      item.itemValue=payload.selection.id;
   
    },

    
    updateItemValue(index, newItemValue) {
      this.items[index].itemValue = newItemValue;
    },
    sendInformation() {
    
  
      const itemlist = this.items
      console.log("sending about settings");
      console.log(itemlist);        // Extract only the `id` of each module

      const data={
          items:itemlist
      }

      this.isLoading = true;
      axios({
        method: "post",
        url: `/admin/radio/aboutSections`,
        data:data,
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
         
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
              text:r.message
            },
          });
          this.isLoading = false;
        });
      return;
    }, 

    repeateAgain() {
    this.items.push({
      id: this.nextTodoId++,
      title: '',
      itemType: null,
      itemValue: null,
    });
   
    this.$nextTick(() => {
      this.initTrHeight(); // Adjust height after adding a row
    });
    this.initTrHeight();
  },
  removeItem(index) {
    if (index >= 0 && index < this.items.length) {
      this.items.splice(index, 1);
      this.$nextTick(() => {
        this.initTrHeight(); // Adjust height after removing a row
      });
    }
  },
  initTrHeight() {
    console.log("in init trHeight:");
    if (this.$refs.Aboutform) {
      const formHeight = this.$refs.Aboutform.scrollHeight;
      this.trSetHeight(formHeight);
      console.log("in init trHeight-  this.trSetHeight(formHeight);:");
      console.log(formHeight);
    }
  },
  trSetHeight(height) {
    this.trHeight = `${height}px`; // Ensure the height is set correctly
  },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow:hidden;
  transition: .35s height;
}
</style>

<template>
    <b-row>
      <!-- Small -->
  
      <!-- Large -->
      <b-col cols="12">
        <b-form-group>
          <v-select
            v-if="categories != null"
            v-model="selectedObject"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="categories"
            class="select-size-lg"
            single
              @input="handleSelectionChanged"
          >
            <template #option="{ title, description }">
              <span> {{ title }}</span><br />
              <small class="text-muted"> {{ description }} </small>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
  </template>
  
  <script>
  import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import axios from '@axios'
  
  export default {
    components: {
      BRow,
      BCol,
      BFormGroup,
      vSelect,
    },
    props: {
        itemId:  {
        type: [String, Number], // Ensure the correct type for the prop
        default: null
      },
      initialCategories: {
        type: [String, Number], // Ensure the correct type for the prop
        default: null
      },
    },
    created() {
   
      this.setselectedItemId();
      this.setcurentItemId() ;
      this.fetchCategories();
    },
    methods: {
        handleSelectionChanged(newSelection) {
      // Emit selectionChanged event with the new value
      var data={item:this.itemId,selection:newSelection};
      console.log(data);
      this.$emit('updateSelectedScreen', data);
    
    },
      setselectedItemId() {
        // Check if initialCategories is available
        
            this.selectedItemId = this.initialCategories;
            console.log("Setting selected Item ", this.selectedItemId)
       
      },
      setcurentItemId() {
        // Check if initialCategories is available
        
            this.currentItemId = this.itemId;
            console.log("Setting selected Item ", this.selectedItemId)
       
      },
  
      fetchCategories() {
      //  console.log('fetchCategories fetch');
        return new Promise((resolve, reject) => {
          axios
            .get('/admin/radio/aboutScreens')
            .then((response) => {
              const data = response.data;
              const responseData = JSON.parse(JSON.stringify(data));
             
              this.categories = responseData.modules;
  
              // If selectedItemId is still null or undefined, assign the first category
              if (!this.selectedItemId) {
             
              } else {
               // console.log("updating selected page with ")
                this.selectedObject = this.categories.find(
                  (x) => x.id === parseInt(this.selectedItemId) 
                );
                // console.log("all of object")
                // console.log(this.categories);
                // console.log("selected object")
                // console.log(this.selectedObject )
              }
            })
            .catch((error) => reject(error));
        });
      },
    },
    watch: {
      initialCategories(newVal) {
      //  console.log('initial category updated');
        console.log(newVal);
        if (!newVal) {
          this.selectedItemId = null;
        } else {
          this.setselectedItemId();
        }
      },
    },
    computed: {
      selectedCategories: {
        get() {
          return this.selectedItemId;
        },
        set(newValue) {
          this.selectedItemId = newValue;
          this.$emit('selectionChanged', newValue);
        },
      },
    },
    data() {
      return {
        currentItemId:null,
        categories: null,
        selectedItemId: null,
        selectedObject:null,
      };
    },
  };
  </script>
  
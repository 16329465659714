<template>
    <b-card>
      <b-form>
        <b-row>
          
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <feather-icon
                icon="MailIcon"
                size="18"
              />
              <h4 class="mb-0 ml-75">
                Application Setttings
              </h4>
            </div>
          </b-col>
             <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
               
              <h6 class="mb-0 ">    
             
              </h6>
            </div>
          </b-col>
          <b-col
  v-if="rradioModules"
  v-for="(item, index) in rradioModules"
  :key="item.id"
  cols="12"
  class="mb-2 d-flex align-items-center"
>
  <!-- Radio Group -->
  <b-form-radio
    :id="'rmodule_' + item.id"
    :value="item.id" 
    v-model="selectedDefaultModuleId"  
    name="default-module-group"
    inline
  >
    <span>{{ item.title }}</span>
  </b-form-radio>


  <!-- Up Button -->
  <b-button
    size="sm"
    variant="primary"
    class="ml-2"
    @click="moveItem(index, 'up')"
    :disabled="index === 0"  
  >
    Up
  </b-button>

  <!-- Down Button -->
  <b-button
    size="sm"
    variant="primary"
    class="ml-1"
    @click="moveItem(index, 'down')"
    :disabled="index === rradioModules.length - 1"  
  >
    Down
  </b-button>
</b-col>
            
  
  
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
               
              <h6 class="mb-0 ">
       
              </h6>
            </div>
          </b-col>
  
           <b-col md="6">
            
          </b-col>
        
          <!--/ quora -->
  
          <b-col cols="12">
            <hr class="my-2">
          </b-col>
  
          <!-- Profile Connections -->
         
          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click.prevent="sendInformation"
            >
              Save changes
            </b-button>
           
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </b-card>
  </template>
  
  <script>
  import {
   BMedia, BMediaAside, BMediaBody, BImg, BButton, BForm, BFormGroup,BFormFile, BFormInput, BRow, BCol, BCard, BCardText, BLink, BAvatar,  BFormRadio,   BFormCheckbox,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import LvColorpicker from 'lightvue/color-picker';
  
  import axios from "@axios";
    import { ref, onUnmounted } from '@vue/composition-api'
    import store from '@/store'
      import { useUtils as useI18nUtils } from '@core/libs/i18n'
    import { useUtils as useAclUtils } from '@core/libs/acl'
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
   
  
  import { useToast } from 'vue-toastification/composition'
  
  export default {
    components: {
      BImg,BMedia, BMediaAside, BMediaBody,  BFormFile,
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BCard,
      BCardText,
      BLink,
      BAvatar,
       BCardText,
      BFormRadio,
          BFormCheckbox,LvColorpicker
  
    },
    directives: {
      Ripple,
    },
    props: {
      smtpsettings: {
        type: Object,
        default: () => {},
      },
    },
     created() {
     
   
  
  
    },
    computed: {
    foregroundColor: {
      get() {
        console.log("in computed value: ",this.appConfigSettings.background_color)
        return this.appConfigSettings.background_color;
      },
      set(value) {
        this.appConfigSettings.foreground_color = value;
      },
    },
  },
   
    data() {
     
  
      return {
        selectedDefaultModuleId: null,
       localSettings:{}, 
       localrradioModules:[],
       colorPickerKey: 0, 
       splashprofileFile: null,
       splashfile: null,
       navfile: null,
       navprofileFile: null,
       backfile: null,
       backprofileFile: null,
        isUpdated: false,
            isLoading: false,
      }
    },
    watch: {
      appConfigSettings:   {
          handler:function (n, o)   {
            console.log('appseting changed in watch ', n);
  
                    
                      console.log('new client: ', n);
                      this.colorPickerKey++;
               
          
  
          },
          deep: true
      },
      selectedDefaultModuleId(newId) {
    // Update the `isDefault` property in the rradioModules array
    this.rradioModules.forEach((module) => {
      module.isDefault = module.id === newId;
    });
    console.log("Updated rradioModules:", this.rradioModules);
  },
      rradioModules: {
        handler(newModules) {
      if (newModules && newModules.length > 0) {
        // Ensure the first item is selected by default if none are marked
        if (!newModules.some(module => module.isDefault)) {
          this.selectedDefaultModuleId = newModules[0].id; // Set the first module as default
          newModules[0].isDefault = true;
        } else {
          // Sync the selectedDefaultModuleId with the current default
          const defaultModule = newModules.find(module => module.isDefault);
          this.selectedDefaultModuleId = defaultModule ? defaultModule.id : null;
        }
      } else {
        this.selectedDefaultModuleId = null; // Handle empty array case
      }
    },
    immediate: true,
    deep: true,
  },
      
  },
    setup(props, { root }) {
        const clientData = ref(null)
      
        const appConfig = ref({})
        const background = ref(null);
  
       // background.value="#fff132";
    
        const appConfigSettings = ref({})
        const rradioModules = ref([])
    
        const toast = useToast()
    
    
        const USER_APP_STORE_rmodule_NAME = 'apps-clients-settings'
    
        // Register module
        if (!store.hasModule(USER_APP_STORE_rmodule_NAME)) store.registerModule(USER_APP_STORE_rmodule_NAME, clientSettingsStoreModule)
    
        // UnRegister on leave
        onUnmounted(() => {
          if (store.hasModule(USER_APP_STORE_rmodule_NAME)) store.unregisterModule(USER_APP_STORE_rmodule_NAME)
        })
    
        const ggetAppConfig=  (ctx, callback) => {  
        store.dispatch('apps-clients-settings/fetchRadyoConfig' )
        .then(response => {
          //    var course=JSON.parse(JSON.stringify(response.data));
        //  const { course  } = response.data
          
          console.log('get app config result')
          console.log(response.data.data);
           appConfigSettings.value=response.data.data;
      
         
        console.log("this background",appConfigSettings.value.background_color )
   
        })
        .catch(error => {
          console.log("Error in ap settings fetch")
          console.log(error);
          if (error.response.status === 404) {
            
          }
        })

    }
         
      const getrradioModules=  (ctx, callback) => {  
          store.dispatch('apps-clients-settings/fetchRadyoModules' )
          .then(response => {
            //    var course=JSON.parse(JSON.stringify(response.data));
          //  const { course  } = response.data
            
            console.log('getrradioModules checked ',response.data)
         
            var result=response.data.modules;
        
             var  checkedModules = result.filter(module => module.checked === true);
             console.log("checked result");
              console.log(checkedModules);
              rradioModules.value=checkedModules;

       
          })
          .catch(error => {
            console.log("Error in ap settings fetch")
            console.log(error);
            if (error.response.status === 404) {
              
            }
          })
  
      }
      ggetAppConfig();
        getrradioModules();
  
        
          const { t } = useI18nUtils()
        return {
            rradioModules,
            getrradioModules,
            ggetAppConfig,
       
          appConfigSettings,
          background,
          clientData,
          t
        }
      },
    methods: {
        updateDefaultModule(selectedId) {
    // Set the selected module's isDefault to true and others to false
    this.rradioModules.forEach(module => {
      module.isDefault = module.id === selectedId;
    });
  },
        moveItem(index, direction) {
      // Determine new index based on direction
      const newIndex = direction === "up" ? index - 1 : index + 1;

      // Swap items in the array
      const temp = this.rradioModules[index];
      this.rradioModules.splice(index, 1);
      this.rradioModules.splice(newIndex, 0, temp);

      // Recalculate the order for all items
      this.updateOrder();
    },
    updateOrder() {
      this.rradioModules.forEach((item, index) => {
        item.order = index + 1; // Set the order based on array position
      });

      console.log("Updated Order:", this.rradioModules);
    },
        updateAppConfig() {
      console.log(this.appConfigSettings);
      console.log("update course  is launching with id : " + this.courseId);
      console.log("updating app config and exited");
       
  
      this.isLoading = true;
      axios({
        method: "put",
        url: `/admin/radio/radioapp`,
        data:this.appConfigSettings,
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
  
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
       sendInformation() {
         
console.log(this.rradioModules);

        const moduleIds = this.rradioModules;
        // Extract only the `id` of each module
  
        const data={
            moduleOrders:moduleIds
        }
  
        this.isLoading = true;
        axios({
          method: "put",
          url: `/admin/radio/orderModules`,
          data:data,
        })
          .then((r) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });
            this.updateAppConfig();
            this.isLoading = false;
            this.isUpdated = false;
          })
          .catch((r) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertOctagonIcon",
                variant: "warning",
                text:r.message
              },
            });
            this.isLoading = false;
          });
        return;
      },
      resetForm() {
        this.appConfigSettings = JSON.parse(JSON.stringify(this.smtpsettings))
      },
    },
  }
  </script>
  
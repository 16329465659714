<template>
  <b-card>
    <b-form>
      <b-row>
        
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="MailIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Application Setttings
            </h4>
          </div>
        </b-col>
           <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
             
            <h6 class="mb-0 ">    
           
            </h6>
          </div>
        </b-col>

        <b-col v-if="radioModules" v-for="items in radioModules" :key="items.id"
      cols="12"
      class="mb-2"
    >
      <b-form-checkbox
      :id="'module_' + items.id" 
     :checked="items.checked"
  @change="(val) => handleCheckboxChange(items, val)"
         :key="items.id"
        name="check-button"
        switch
        inline
        :disabled="items.status === 100" 
      >
        <span>{{ items.title }}</span>
      </b-form-checkbox>
    </b-col> 
          

        <b-col md="6">
          <b-form-group
            label="Sponsor Enabled"
            label-for="isSecured"
          >
           <div class="demo-inline-spacing mb-1">
            <b-form-radio v-model="appConfigSettings.sponsorship_enabled"   name="sponsorship_enabled" v-bind:value="false" >
              Disabled
            </b-form-radio>
            <b-form-radio v-model="appConfigSettings.sponsorship_enabled"   name="sponsorship_enabled" v-bind:value="true" >
              Enabled
            </b-form-radio>
          </div>
          </b-form-group>
        </b-col>


        <b-col md="6">
          <b-form-group
            label="Use Radio Specific Backgrounds"
            label-for="use_radio_based_bg"
          >
           <div class="demo-inline-spacing mb-1">
            <b-form-radio v-model="appConfigSettings.use_radio_based_bg"   name="use_radio_based_bg" v-bind:value="false" >
              False
            </b-form-radio>
            <b-form-radio v-model="appConfigSettings.use_radio_based_bg"   name="use_radio_based_bg" v-bind:value="true" >
              True
            </b-form-radio>
          </div>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
             
            <h6 class="mb-0 ">
     
            </h6>
          </div>
        </b-col>

         <b-col md="6">
          
        </b-col>
      
        <!--/ quora -->

        <b-col cols="12">
          <hr class="my-2">
        </b-col>

        <!-- Profile Connections -->
       
        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click.prevent="sendInformation"
          >
            Save changes
          </b-button>
         
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
 BMedia, BMediaAside, BMediaBody, BImg, BButton, BForm, BFormGroup,BFormFile, BFormInput, BRow, BCol, BCard, BCardText, BLink, BAvatar,  BFormRadio,   BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import LvColorpicker from 'lightvue/color-picker';

import axios from "@axios";
  import { ref, onUnmounted } from '@vue/composition-api'
  import store from '@/store'
    import { useUtils as useI18nUtils } from '@core/libs/i18n'
  import { useUtils as useAclUtils } from '@core/libs/acl'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

 

import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BImg,BMedia, BMediaAside, BMediaBody,  BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
     BCardText,
    BFormRadio,
        BFormCheckbox,LvColorpicker

  },
  directives: {
    Ripple,
  },
  props: {
    smtpsettings: {
      type: Object,
      default: () => {},
    },
  },
   created() {
   
 


  },
  computed: {
  foregroundColor: {
    get() {
      console.log("in computed value: ",this.appConfigSettings.background_color)
      return this.appConfigSettings.background_color;
    },
    set(value) {
      this.appConfigSettings.foreground_color = value;
    },
  },
},
 
  data() {
   

    return {
     localSettings:{}, 
     localRadioModules:[],
     colorPickerKey: 0, 
     splashprofileFile: null,
     splashfile: null,
     navfile: null,
     navprofileFile: null,
     backfile: null,
     backprofileFile: null,
      isUpdated: false,
          isLoading: false,
    }
  },
  watch: {
    appConfigSettings:   {
        handler:function (n, o)   {
          console.log('appseting changed in watch ', n);

                  
                    console.log('new client: ', n);
                    this.colorPickerKey++;
             
        

        },
        deep: true
    },
    radioModules:   {
        handler:function (n, o)   {
          console.log('appseting changed in watch ', n);

                  
                    console.log('new client: ', n);
                    this.localRadioModules=n;
             
        

        },
        deep: true
    }
    
},
  setup(props, { root }) {
      const clientData = ref(null)
    
      const appConfig = ref({})
      const background = ref(null);

     // background.value="#fff132";
  
      const appConfigSettings = ref({})
      const radioModules = ref([])
  
      const toast = useToast()
  
  
      const USER_APP_STORE_MODULE_NAME = 'apps-clients-settings'
  
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, clientSettingsStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      })
  
    const getAppConfig=  (ctx, callback) => {  
        store.dispatch('apps-clients-settings/fetchRadyoConfig' )
        .then(response => {
          //    var course=JSON.parse(JSON.stringify(response.data));
        //  const { course  } = response.data
          
          console.log('get app config result')
          console.log(response.data.data);
           appConfigSettings.value=response.data.data;
      
         
        console.log("this background",appConfigSettings.value.background_color )
   
        })
        .catch(error => {
          console.log("Error in ap settings fetch")
          console.log(error);
          if (error.response.status === 404) {
            
          }
        })

    }
       
    const getradioModules=  (ctx, callback) => {  
        store.dispatch('apps-clients-settings/fetchRadyoModules' )
        .then(response => {
          //    var course=JSON.parse(JSON.stringify(response.data));
        //  const { course  } = response.data
          
        radioModules.value = response.data.modules.map(module => ({
      ...module,
      checked: module.checked || false, // Ensure `checked` exists
    }));
      
         
     
        })
        .catch(error => {
          console.log("Error in ap settings fetch")
          console.log(error);
          if (error.response.status === 404) {
            
          }
        })

    }
  
     
      getAppConfig();
      getradioModules();

      
        const { t } = useI18nUtils()
      return {
          radioModules,
          getradioModules,
        getAppConfig,
     
        appConfigSettings,
        background,
        clientData,
        t
      }
    },
  methods: {
    handleCheckboxChange(item, value) {
    console.log('Checkbox changed:', item.id, value);
    item.checked = value; // Explicitly update the `checked` state
  },
    
      updateAppConfig() {
    console.log(this.appConfigSettings);
    console.log("update course  is launching with id : " + this.courseId);
    console.log("updating app config and exited");
     

    this.isLoading = true;
    axios({
      method: "put",
      url: `/admin/radio/radioapp`,
      data:this.appConfigSettings,
    })
      .then((r) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });

        this.isLoading = false;
        this.isUpdated = false;
      })
      .catch((r) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "AlertOctagonIcon",
            variant: "warning",
          },
        });
        this.isLoading = false;
      });
    return;
  },
     sendInformation() {
      console.log(this.localRadioModules);
  
      const moduleIds = this.radioModules
  .filter(module => module.checked)  // Filter modules where checked is true
  .map(module => module.id);         // Extract only the `id` of each module

      const data={
          moduleIds:moduleIds
      }

      this.isLoading = true;
      axios({
        method: "put",
        url: `/admin/radio/radiomodules`,
        data:data,
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.updateAppConfig();
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
              text:r.message
            },
          });
          this.isLoading = false;
        });
      return;
    },
    resetForm() {
      this.appConfigSettings = JSON.parse(JSON.stringify(this.smtpsettings))
    },
  },
}
</script>

<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold"> {{ t('General') }}</span>
      </template>

      <account-setting-general
        v-if="clientData"
        :general-data="clientData"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->

    <!--/ change password tab -->

    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="GlobeIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ t('Regional Settings') }}</span>
      </template>

      <account-setting-regional
        v-if="clientData"
        :clientdata="clientData"
      />
    </b-tab>
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ t('SMTP Settings')}}</span>
      </template>

      <account-setting-smtp-settings
        v-if="clientData"
        :smtpsettings="clientData"
      />
    </b-tab>

    <!-- social links
    <b-tab>

      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Social</span>
      </template>

      <account-setting-social
        v-if="options.data"
        :social-data="options.data"
      />
    </b-tab>
   ocial links -->
    <!-- notification -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ t('Notifications')}}</span>
      </template>

      <account-setting-notification
        v-if="clientData"
        :notification-data="clientData"
      />
    </b-tab>
    <!--   <b-tab>

     title
      <template #title>
        <feather-icon
          icon="MailIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Mail Templates</span>
      </template>

      <account-setting-social
        v-if="clientData"
        :social-data="clientData"
      />
    </b-tab>-->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="MessageCircleIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ t('Mail Templates')}}</span>
      </template>

      <account-setting-mail-templates
        v-if="clientData"
        title="Mail Templates"
        :content-data="clientData"
        :social-data="clientData"
      />
    </b-tab>
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ t('Accounting Preferences')}}</span>
      </template>

      <account-setting-preferences
        v-if="clientData"
        :account-preferences="clientData"
      />
    </b-tab>

    <b-tab>

<!-- title -->
<template #title>
  <feather-icon
    icon="BellIcon"
    size="18"
    class="mr-50"
  />
  <span class="font-weight-bold">{{ t('Application  Settings')}}</span>
</template>

<app-config-settings
  v-if="clientData"
  :account-preferences="clientData"
/>
</b-tab>

<b-tab>
  <template #title>
  <feather-icon
    icon="BellIcon"
    size="18"
    class="mr-50"
  />
  <span class="font-weight-bold">{{ t('Screen Settings')}}</span>
</template>
<app-screen-arrangement
  v-if="clientData"
  :account-preferences="clientData"
/>
</b-tab>
 
    <b-tab>

<!-- title -->
<template #title>
  <feather-icon
    icon="BellIcon"
    size="18"
    class="mr-50"
  />
  <span class="font-weight-bold">{{ t('Radio App Configuration')}}</span>
</template>



<radio-app
  v-if="clientData"
  :account-preferences="clientData"
/>
</b-tab>
<b-tab>

<!-- title -->
<template #title>
  <feather-icon
    icon="RadioIcon"
    size="18"
    class="mr-50"
  />
  <span class="font-weight-bold">{{ t('Radio About Screen')}}</span>
</template>



<radio-about-section 
/>
</b-tab>


    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="StarIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ t('Loyalty Settings') }}</span>
      </template>

      <account-setting-loyalty
        v-if="clientData"
        :account-preferences="clientData"
      />
    </b-tab>
    <b-tab>

<!-- title -->
<template #title>
  <feather-icon
    icon="StarIcon"
    size="18"
    class="mr-50"
  />
  <span class="font-weight-bold">{{ t('Loyalty Awards') }}</span>
</template>

<loyalty-awards
  v-if="clientData"
  :account-preferences="clientData"
/>
</b-tab>
    <b-tab v-if="6>7">

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Modules</span>
      </template>

      <account-setting-modules
        v-if="clientData"
        :account-preferences="clientData"
      />
    </b-tab>

    <account-migrator
      v-if="migratorEnable"
      :clientdata="clientData"
    />

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import clientSettingsStoreModule from './clientSettingsStoreModule'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingSmtpSettings from './AccountSettingSMTPSettings.vue'
import RadioApp from './RadioApp.vue'
import RadioAboutSection from './AboutItemRepeater.vue'
import AppConfigSettings from './AppConfigSetting.vue'
import AppScreenArrangement from './AppScreenArrangement.vue'
import AccountSettingRegional from './AccountSettingRegional.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'
import AccountSettingMailTemplates from './AccountSettingMailTemplates.vue'
import AccountMigrator from './migration/AccountMigrator.vue'
import AccountSettingPreferences from './AccountSettingPreferences.vue'
import AccountSettingModules from './ClientModules.vue'
import AccountSettingLoyalty from './AccountSettingLoyalty.vue'
import LoyaltyAwards from './LoyaltyAwards.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
export default {
  components: {
    LoyaltyAwards,
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingInformation,
    AppScreenArrangement,
    AppConfigSettings,
    RadioApp,
    AccountSettingSmtpSettings,
    AccountSettingSocial,
    AccountSettingNotification,
    AccountSettingMailTemplates,
    AccountSettingRegional,
    AccountSettingPreferences,
    AccountMigrator,
    AccountSettingModules,
    AccountSettingLoyalty,
    RadioAboutSection
  },
  data() {
    return {
      settingsInfo: this.clientData == null || this.clientData.data == null ? {} : JSON.parse(JSON.stringify(this.clientData.data)),

    }
  },
  computed: {
    migratorEnable() {
      return false
      return useJwt.getcustomlogin()
    },

  },
  setup() {
    const clientData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'apps-clients-settings'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, clientSettingsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('apps-clients-settings/fetchClientSettings', { id: router.currentRoute.params.id })
      .then(response => {
        //    var course=JSON.parse(JSON.stringify(response.data));
      //  const { course  } = response.data
        clientData.value = response.data
        console.log('courses assigned to course data')
        console.log(clientData)
        console.log('=========')

        // console.log(response.data);
      })
      .catch(error => {
        if (error.response.status === 404) {
          clientData.value = undefined
        }
      })
      const { t } = useI18nUtils()
    return {
      clientData,
      t
    }
  },
  beforeCreate() {
    const userData = localStorage.getItem('userData')
    console.log(userData)
    const userInfo = JSON.parse(userData)
    console.log(`userInfo ${userInfo}`)
    const settings = userInfo.setting
    // this.options=settings;
  },
}
</script>
